import api from './http'
//商品分类
const showInfoCreate =(data)=>api.post('/sign',data)
const getOpenidHandler = (data) =>api.get(`/order/getOpenId/${data}`)
const getTickHandler = () =>api.get(`/order/getTicketPrice`)
const wxPayHandler = (data) =>api.post(`/order/preOrder`,data)
const getMapHandler = (data) =>api.get(`/order/genMapSign?url=${data}`)
export default{
    showInfoCreate,
  getOpenidHandler,
  wxPayHandler,
  getTickHandler,
    getMapHandler
}
