import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.less';
import api from "./api"
Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.config.devtools = false
Vue.config.silent = true
import {
  Button,Form, Field, Toast , Image, Lazyload, Uploader,Popup,Stepper ,Swipe, SwipeItem, Image as VanImage 
} from 'vant'

Vue.use(Button).use(Form).use(Field).use(Image).use(Toast).use(Lazyload).use(Uploader).use(Toast).use(Popup).use(Stepper).use(Swipe).use(SwipeItem).use(VanImage)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
