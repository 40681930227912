import axios from 'axios'
// 进行一些全局配置
axios.defaults.baseURL = 'https://api.yzpjt.com/rcu/wechat/pay'
//axios.defaults.baseURL = 'https://34330745e8.picp.vip/api'
// axios.defaults.baseURL = 'http://localhost:8088/api'
//axios.defaults.baseURL = 'https://test.azureone.cn/api'
axios.defaults.timeout = 5000;

let instance = axios.create({timeout: 1000 * 12});
instance.defaults.headers.post['Content-Type'] = 'application/json';
//instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    let user = JSON.parse(localStorage.getItem("user"));
    //console.log(user.token)
    if (user) {
        const token = user.token
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
}, function (error) {
    return Promise.reject(error)
})
//添加响应拦截器
axios.interceptors.response.use(function (response) {
    let status = response.data.status
    if (status === 1001) {
        Message.warning('请求参数错误')
    } else if (status === 1002) {
        Message.error('服务繁忙')
    }
    //console.log(response.data.status)
    return response
}, function (error) {
    return Promise.reject(error)
})
//封装CRUD方法
export default {
    get: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.get(path, {
                params: data
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    post: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.post(path, data)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    put: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.put(path, data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    delete: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.delete(path, data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
}
